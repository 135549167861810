<template>
  <view class="code">
    <view class="text">处理中...</view>
  </view>
</template>

<script>
  import { mapState, mapGetters } from "vuex";
  import { baseUrl } from '@/environment/index.js'

  export default {
    data() {
      return {
        user: {},
        zoneId: this.$route.query.zoneId, // 学校zoneId
        type: this.$route.query.type,
        id: this.$route.query.id
      }
    },
    computed: {
      // 使用对象展开运算符将 getter 混入 computed 对象中
      ...mapGetters(["returnRoleData"]),
    },
    created() {
      this.user = this.returnRoleData.userPO
      if(this.user) {
        if (this.type === 'zone') {
          let url = `${window.location.protocol}//${window.location.host}${baseUrl}/wx/callback/${this.zoneId}?openid=${this.user.gzhOpenId}`
          console.log('codeUrl', url)
          window.location.href = url;
        } else {
          let url = `${window.location.protocol}//${window.location.host}${baseUrl}/wx/business/${this.id}?openid=${this.user.gzhOpenId}`
          console.log('codeUrl', url)
          window.location.href = url;
        }
        // } else {
        // this.$http.get('/user/getUserInfo').then(res => {
        //   if (res.data.code === 'SUCCESS') {
        //     let user = res.data.data.userPO;
        //     let url = `${window.location.protocol}//${window.location.host}/mobile-backend/wx/callback/${option.zoneId}?openid=${user.openId}`
        //     console.log('codeUrl', url)
        //     window.location.href = url;
        //   } else {
        //   }
        // }).catch(err => {
        // })
      }
    },
    methods: {
      getUserInfo: function() {
        
      }
    }
  }
</script>

<style scoped>
  .text{
    margin-top: 40px;
    text-align: center;
  }
</style>